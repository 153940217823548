@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

.map-container {
  height: 650px;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  margin: 12px;
  border-radius: 4px;
}

.mapboxgl-popup {
  width: 500px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.mapboxgl-popup-content h2 {
  font-weight: bold;
  font-size: small;
  padding: 6px 0;
  margin: 4px 0;
}

.mapboxgl-popup-content div {
  border: 1px solid #000;
}

.mapboxgl-popup-content hr {
  border: 1px solid #000;
}

.mapboxgl-popup-close-button {
  display: none;
}
